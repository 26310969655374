<template>
  <div>
    <Dialog
      :visible.sync="abrirModal"
      :containerStyle="{ width: '500px' }"
      :closable="false"
      :modal="true">
      <template #header>
        <i class="pi pi-lock"></i>
        <h5>{{ msg }}</h5>
      </template>
      <div class="grid">
        <div class="col-12 md:col-12">
          <div class="p-fluid formgrid grid">
            <div v-if="isNovaAverbacao == true" class="field col-12 md:col-12">
              <label for="rubrica">
                <strong>Rubrica: </strong>{{ showConsignacao.rubrica }} </label
              ><br />
              <label for="numeroContrato">
                <strong>Nº Contrato: </strong>
                {{ showConsignacao.numeroContrato }} </label
              ><br />
              <label for="valorTotal" mode="decimal">
                <strong>Valor Parcela: </strong> <span>R$</span>
                {{ showConsignacao.valorParcela }} </label
              ><br />
              <label for="prazo">
                <strong>Prazo: </strong>
                {{ showConsignacao.prazoTotal }} </label
              ><br />
              <label for="carencia">
                <strong>Data da Primeira Parcela:</strong>
                {{ showConsignacao.carencia }}
              </label>
            </div>
            <div v-if="isReservaCartao == true" class="field col-12 md:col-12">
              <label for="rubrica">
                <strong>Rubrica: </strong>
                {{ showReservaCartao.rubrica.rubrica }} |
                {{ showReservaCartao.rubrica.nome }} |
                {{ showReservaCartao.rubrica.atividade.nome }}
              </label>
              <br />
              <label for="valor">
                <strong>Valor: </strong>
                R$ {{ parseFloat(showReservaCartao.valor).toFixed(2) }}
              </label>
            </div>
            <div v-if="mfaEnabled" class="field col-12 md:col-12">
              <label v-if="tipo == true" for="senha"
                >Por favor, <strong>SERVIDOR</strong>, digite sua senha:
              </label>
              <label v-else for="senha"
                >Por favor, <strong>USUÁRIO</strong>, digite sua senha:
              </label>
              <InputText
                id="senha"
                :value="maskedPassword"
                type="password"
                :feedback="false"
                disabled
                class="text-gray-700 bg-gray-200 border border-gray-300 p-2 rounded"
                @keyup.enter="fecharModal" />
              <div v-if="mfaEnabled" class="password-detected-message">
                <i class="pi pi-check text-green-500"></i> Senha detectada
              </div>
            </div>
            <div v-if="!mfaEnabled" class="field col-12 md:col-12">
              <label v-if="tipo == true" for="senha"
                >Por favor, <strong>SERVIDOR</strong>, digite sua senha:
              </label>
              <label v-else for="senha"
                >Por favor, <strong>USUÁRIO</strong>, digite sua senha:
              </label>
              <InputText
                id="senha"
                v-model="v$.password.$model"
                type="password"
                :feedback="false"
                :class="{
                  'p-invalid':
                    submitted && (v$.password.$invalid || senhaInvalida),
                }"
                @keyup.enter="fecharModal" />
              <div
                v-if="submitted && v$.password.required.$invalid"
                class="p-error">
                O campo senha é obrigatório
              </div>
              <div v-if="submitted && senhaInvalida" class="p-error">
                Senha inválida
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :disabled="executarMetodoSalvar"
          class="p-button-danger"
          @click="sairModal">
          <div>
            <i class="pi pi-times ml-2"></i>
            <span class="mr-2 ml-2">{{ reject() }}</span>
          </div>
        </Button>
        <Button
          :disabled="executarMetodoSalvar || !passwordFilled"
          autofocus
          @click="fecharModal1">
          <div v-if="!executarMetodoSalvar">
            <i class="pi pi-check ml-2"></i>
            <span class="mr-2 ml-2">{{ confirm1() }}</span>
          </div>
          <div v-if="executarMetodoSalvar">
            <i class="pi pi-spin pi-spinner ml-2"></i>
            <span class="mr-2 ml-2">Aguarde</span>
          </div>
        </Button>
        <Button
          v-if="
            labelConfirm2Button !== undefined &&
            labelConfirm2Button !== null &&
            labelConfirm2Button !== ''
          "
          :disabled="executarMetodoSalvar || passwordFilled"
          autofocus
          @click="fecharModal2">
          <div v-if="!executarMetodoSalvar">
            <i class="pi pi-check ml-2"></i>
            <span class="mr-2 ml-2">{{ confirm2() }}</span>
          </div>
          <div v-if="executarMetodoSalvar">
            <i class="pi pi-spin pi-spinner ml-2"></i>
            <span class="mr-2 ml-2">Aguarde</span>
          </div>
        </Button>
      </template>
    </Dialog>
  </div>
</template>
<script>
import UseVuelidate from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import ServidorService from '@/service/ServidorService.js'
import { servidorStore } from '@/stores/servidor'

export default {
  props: {
    msg: {
      type: String,
      default: 'Confirmar Operação',
    },
    exibir: {
      type: Boolean,
      default: false,
    },
    metodoSalvar: {
      type: Function,
      default: null,
    },
    metodoSalvar2: {
      type: Function,
      default: null,
    },
    tipo: {
      type: Boolean,
      default: false,
    },
    mfaEnabled: {
      type: Boolean,
      default: false,
    },
    isCompraDivida: {
      type: Boolean,
      default: false,
    },
    isNovaAverbacao: {
      type: Boolean,
      default: false,
    },
    showConsignacao: {
      type: Object,
      default: null,
    },
    labelConfirm1Button: {
      type: String,
      default: 'Confirmar',
    },
    labelConfirm2Button: {
      type: String,
      default: null,
    },
    labelRejectButton: {
      type: String,
      default: 'Sair',
    },
    isReservaCartao: {
      type: Boolean,
      default: false,
    },
    showReservaCartao: {
      type: Object,
      default: null,
    },
  },

  setup() {
    const store = servidorStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      submitted: false,
      username: null,
      password: null,
      statusSenhaVerificada: null,
      senhaInvalida: false,
      executarMetodoSalvar: false,
    }
  },

  computed: {
    abrirModal: {
      get() {
        return this.exibir
      },
      set(value) {
        this.$emit('mudarVisibilidade', value)
        this.limparCampos()
      },
    },
    passwordFilled() {
      if (this.mfaEnabled) {
        return true
      }
      if (this.password == null || this.password == '') {
        return false
      }
      return true
    },
    maskedPassword() {
      return this.mfaEnabled ? '********' : this.password
    },
  },

  validations() {
    return {
      password: {
        required: requiredIf(() => !this.mfaEnabled),
      },
    }
  },

  created() {
    this.servidorService = new ServidorService(this.$http)
  },

  methods: {
    confirm1() {
      return this.labelConfirm1Button
    },

    confirm2() {
      return this.labelConfirm2Button
    },

    reject() {
      return this.labelRejectButton
    },

    sairModal() {
      this.$emit('mudarVisibilidade', false)
      if (this.isCompraDivida) this.$router.push({ name: 'servidores-margem' })
    },

    fecharModal2() {
      this.$emit('mudarVisibilidade', false)
      if (this.isCompraDivida) {
        this.$router.push({ name: 'servidores-margem' })
      } else if (this.metodoSair !== null) {
        this.metodoSalvar2()
        this.limparCampos()
      }
    },

    fecharModal1() {
      this.submitted = true
      if (!this.mfaEnabled) {
        this.v$.password.$touch()
      }
      if (this.v$.password.$invalid) {
        return
      } else {
        this.validarSenha()
      }
    },
    async validarSenha() {
      this.executarMetodoSalvar = true

      if (this.mfaEnabled) {
        this.statusSenhaVerificada = 200
        this.salvar()
        this.limparCampos()
        this.executarMetodoSalvar = false
        this.sairModal()
        return
      }

      if (this.tipo) {
        this.username = this.store.dados.servidor
          ? this.store.dados.servidor.usuario.username
          : ''
      } else {
        this.username = this.$auth.user ? this.$auth.user.usuario : ''
      }

      this.servidorService
        .validarSenhaServidor(this.username, this.password)
        .then((res) => {
          this.statusSenhaVerificada = res.status
          this.salvar()
          this.limparCampos()
          this.executarMetodoSalvar = false
        })
        .catch((err) => {
          this.executarMetodoSalvar = false
          this.senhaInvalida = true
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },
    salvar() {
      if (this.statusSenhaVerificada === 200) {
        this.metodoSalvar()
        this.$emit('mudarVisibilidade', false)
      }
    },
    limparCampos() {
      this.password = null
      this.submitted = false
      this.senhaInvalida = false
      // this.executarMetodoSalvar = false -> impede o mostrar o aguarde ao usuario
      this.v$.$reset()
      //this.$emit('mudarVisibilidade', false)
    },
  },
}
</script>
<style scoped>
h5 {
  font-weight: bold;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.password-detected-message {
  margin-top: 10px;
  color: green;
  display: flex;
  align-items: center;
}
.password-detected-message i {
  margin-right: 5px;
}
</style>
