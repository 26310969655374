import CryptoJS from 'crypto-js'
export default class ServidorService {
  constructor(http) {
    this._http = http
  }

  async getServidores(dados) {
    const { data } = await this._http.get(
      `/api/servidores/${dados.page}/${dados.rows}`,
    )
    return data
  }

  async atualizarServidor(matriculaId, servidor) {
    if (servidor.id) {
      return await this._http.put(
        `/api/servidores/matricula/${matriculaId}`,
        servidor,
      )
    }
  }

  async deleteServidor(servidor) {
    await this._http.delete(`/api/servidores/${servidor.id}`)
  }

  async getServidor(id) {
    const { data } = await this._http.get(`/api/servidores/${id}`)
    return data
  }

  async getDadosServidor() {
    const { data } = await this._http.get(`/api/servidores/dados-servidor`)
    return data
  }

  async validarSenhaServidor(username, senha) {
    var key = CryptoJS.enc.Latin1.parse('1234567812345678')
    var iv = CryptoJS.enc.Latin1.parse('1234567812345678')
    var encrypted = CryptoJS.AES.encrypt(senha, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    })

    let dados = {
      username: username,
      password: encrypted.toString(),
    }

    return await this._http.post('/api/sso/checkpassword', dados)
  }

  async buscaServidor(matriculaCpf) {
    const { data } = await this._http.get(
      `/api/servidores/matriculaCpf/${matriculaCpf}`,
    )
    return data
  }
}
